import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { CustomerRequest } from 'src/app/models/customer-request';
import { CustomerRequestMessageDataService } from 'src/app/services/customer-request-message-data/customer-request-message-data.service';
import { CustomerRequestMessage } from 'src/app/models/customer-request-message';

@Component({
  selector: 'app-customer-request-messages',
  templateUrl: './customer-request-messages.component.html',
  styleUrls: ['./customer-request-messages.component.scss'],
})
export class CustomerRequestMessagesComponent implements OnInit {

  @Input() customerRequest: CustomerRequest;

  subscription: Subscription;
  customerRequestMessages: CustomerRequestMessage[];

  constructor(
    private customerRequestMessageData: CustomerRequestMessageDataService
  ) { }

  ngOnInit() {
    this.subscription = this.customerRequestMessageData.getCustomerRequestMessagesForCustomerRequest(this.customerRequest).valueChanges()
    .subscribe(customerRequestMessages => {
      this.customerRequestMessages = [];
      for(const customerRequestMessage of customerRequestMessages) {
        if (!customerRequestMessage.staffCreated) {
          this.customerRequestMessages.push(customerRequestMessage)
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
