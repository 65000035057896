import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Brand } from './models/brand';
import { BrandDataService } from './services/brand-data/brand-data.service';
import { ThemeService } from './services/theme/theme.service';
@Injectable()
export class Globals {
  
  public isNative: boolean;
  public darkMode: boolean = true;

  private firebaseUserSubject = new ReplaySubject<firebase.User>(1);
  firebaseUser$: Observable<firebase.User> = this.firebaseUserSubject.asObservable();

  brandSubscription: Subscription;
  private brandSubject = new ReplaySubject<Brand>(1);
  brand$: Observable<Brand> = this.brandSubject.asObservable();
  
  constructor(
    private platform: Platform,
    private brandData: BrandDataService,
    private theme: ThemeService
  ) { 
    
    //get platform
    this.platform.ready().then(async () => {
      try {
        if (this.platform.is('capacitor') || this.platform.is('cordova')) {
          this.isNative = true;
        } else {
          this.isNative = false;
        }
      } catch(error) {
        console.error(error);
      }
    });
  }
  
  async setFirebaseUser(firebaseUser:firebase.User) {
    try {
      this.firebaseUserSubject.next(firebaseUser);
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async setBrand(brandUid:string) {
    try {
      if (this.brandSubscription) this.brandSubscription.unsubscribe();
      this.brandSubscription = this.brandData.getBrand(brandUid).valueChanges().subscribe(brand => {
        this.brandSubject.next(brand);
        //update theme from brand
        this.theme.setTheme(brand.themeColors, this.darkMode);
      });
      return;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  
}