import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Component({
  selector: 'app-time-since-timer',
  templateUrl: './time-since-timer.component.html',
  styleUrls: ['./time-since-timer.component.scss'],
})
export class TimeSinceTimerComponent implements OnInit, OnDestroy {

  @Input() startDate: firebase.firestore.Timestamp;
  @Input() setRedAfterSeconds: number;

  timerSubscription: Subscription;
  timer: number;

  constructor() {}

  ngOnInit() {
    this.timerSubscription = timer(1000, 1000).subscribe(val => {
      if (this.startDate) {
        const currentDateInSeconds = moment();
        this.timer = currentDateInSeconds.diff(moment(this.startDate.toDate()), 'seconds');
      }
    });
  }

  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }

}
