import { Component, HostListener, OnInit } from '@angular/core';
import { of, Subscription, timer } from 'rxjs';
import { Globals } from 'src/app/globals';
import { switchMap, map } from 'rxjs/operators';
import { TvDisplayService } from 'src/app/services/tv-display/tv-display.service';
import { TvDisplay } from 'src/app/models/tv-display';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment-timezone';
import { StoreLocation } from 'src/app/models/store-location';
import { StoreLocationService } from 'src/app/services/store-location/store-location.service';
import { ActivatedRoute } from '@angular/router';
import { Version } from 'src/app/models/version';
import { VERSION } from 'src/environments/version';
import { ErrorAlertComponent } from 'src/app/components/error-alert/error-alert.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  version: Version;

  subscription: Subscription;
  firebaseUser: firebase.User;
  tvDisplay: TvDisplay;
  storeLocation: StoreLocation;

  tvDisplayDimensionsSaved: boolean;

  timerSubscription: Subscription;
  timer: string;

  previewModeTvDisplayUid: string; //used for previewing override

  constructor(
    private globals: Globals,
    private tvDisplayService: TvDisplayService,
    private storeLocationService: StoreLocationService,
    private route: ActivatedRoute,
    private errorAlert: ErrorAlertComponent
  ) { 
    this.version = VERSION;
    this.previewModeTvDisplayUid = this.route.snapshot.paramMap.get('tvDisplayUid');
  }

  ngOnInit() {
    this.subscription = this.globals.firebaseUser$.pipe(
      switchMap(firebaseUser => {
        //if a specific tv display UID was provided, use it for previewing
        if (this.previewModeTvDisplayUid) {
          return this.tvDisplayService.getTvDisplay(this.previewModeTvDisplayUid).valueChanges().pipe(
            map(tvDisplay => ({firebaseUser, tvDisplay}))
          );
        } else {
          return this.tvDisplayService.getTvDisplay(firebaseUser.uid).valueChanges().pipe(
            map(tvDisplay => ({firebaseUser, tvDisplay}))
          );
        }
      })
    ).subscribe(async ({firebaseUser, tvDisplay}) => {
      this.firebaseUser = firebaseUser;
      
      if (tvDisplay) {

        //set tvDisplay to first in array (should only be one assigned at a time)
        this.tvDisplay = tvDisplay;

        if (this.tvDisplay.locationUid) {
          const storeLocationSnap = await this.storeLocationService.getStoreLocation(this.tvDisplay.locationUid).get().toPromise();
          this.storeLocation = storeLocationSnap.data();
          //set timezone
          if (this.storeLocation && this.storeLocation.timezone) moment.tz.setDefault(this.storeLocation.timezone);
        }

        //save initial screen dimensions
        if (!this.tvDisplayDimensionsSaved && !this.previewModeTvDisplayUid) {
          this.saveTvDisplayDimensions();
          this.tvDisplayDimensionsSaved = true;
        }

      } else {
        this.tvDisplay = undefined;
        this.tvDisplayDimensionsSaved = false;
      }
    }, (err) => this.errorAlert.show(err));

    /**
     * update ping and active tv display
     */
    if (!this.previewModeTvDisplayUid) {
      this.timerSubscription = timer(2000, 5000).subscribe(val => {
        if (this.tvDisplay) {
          /**
           * update ping on server to let it know the display is on
           */
          this.tvDisplayService.updateTvDisplay({uid: this.tvDisplay.uid, ping: firebase.firestore.FieldValue.serverTimestamp()});
        }
      });
    }
  }

  /**
   * Update tvDisplay Dimensions on window resize
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  async saveTvDisplayDimensions(event?) {
    try {
      if (this.tvDisplay && this.tvDisplay.uid) {
        await this.tvDisplayService.updateTvDisplay({uid: this.tvDisplay.uid, innerHeight: window.innerHeight, innerWidth: window.innerWidth});
      }
    } catch(err) {
      throw err;
    }
    
  }
  
}
