// REFERENCE:
// https://github.com/AngularFirebase/128-ionic4-theme-generator
// https://angularfirebase.com/lessons/css-variables-in-ionic-4/
// https://www.youtube.com/watch?v=soADEpvGi1Q&ck_subscriber_id=402016076

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as Color from 'color';
import { Storage } from '@ionic/storage';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
const { StatusBar } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage
  ) {
    // storage.get('theme').then(cssText => {
    //   this.setGlobalCSS(cssText);
    // });
  }

  // Override all global variables with a new theme
  async setTheme(theme: any, shouldBeDark: boolean) {
    this.setDarkClass(shouldBeDark);

    const cssText = CSSTextGenerator(theme, shouldBeDark);
    this.setGlobalCSS(cssText);

    if (Capacitor.isPluginAvailable('StatusBar')) {
      if (shouldBeDark) {
        StatusBar.setStyle({style: StatusBarStyle.Dark});
      } else {
        StatusBar.setStyle({style: StatusBarStyle.Light});
      }
    }
    // this.storage.set('theme', cssText);
  }

  setDarkClass(shouldBeDark) {
    //add dark class to body if necessary
    document.body.classList.toggle('dark', shouldBeDark);
  }

  // Define a single CSS variable
  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }

  // get storedTheme() {
  //   return this.storage.get('theme');
  // }
}

const defaults = {
  primary: '#3880ff',
  secondary: '#0cd1e8',
  tertiary: '#7044ff',
  success: '#10dc60',
  warning: '#ffce00',
  danger: '#f04141',
  dark: '#222428',
  medium: '#989aa2',
  light: '#f4f5f8',
  toolbarText: '#222428',
  link: '#4D8CF4',
};

function CSSTextGenerator(colors, shouldBeDark: boolean) {
  colors = { ...defaults, ...colors };

  const {
    primary,
    secondary,
    tertiary,
    success,
    warning,
    danger,
    dark,
    medium,
    light,
    toolbarText,
    link,
    menuBackground,
    menuText,

    primaryDark,
    secondaryDark,
    tertiaryDark,
    successDark,
    warningDark,
    dangerDark,
    darkDark,
    mediumDark,
    lightDark,
    toolbarTextDark,
    linkDark,
    menuBackgroundDark,
    menuTextDark
  } = colors;

  const shadeRatio = 0.1;
  const tintRatio = 0.1;

  if (shouldBeDark) {
    return `
      --ion-item-background-color: ${contrast(lightDark, 0.3)};
      --ion-item-text-color: ${contrast(darkDark, 0.3)};
      --ion-color-primary: ${primaryDark};
      --ion-color-primary-rgb: ${rgbColor(primaryDark)};
      --ion-color-primary-contrast: ${contrast(primaryDark)};
      --ion-color-primary-contrast-rgb: ${rgbColorContrast(primaryDark)};
      --ion-color-primary-shade:  ${Color(primaryDark).darken(shadeRatio)};
      --ion-color-primary-tint:  ${Color(primaryDark).lighten(tintRatio)};
      --ion-color-secondary: ${secondaryDark};
      --ion-color-secondary-rgb: ${rgbColor(secondaryDark)};
      --ion-color-secondary-contrast: ${contrast(secondaryDark)};
      --ion-color-secondary-contrast-rgb: ${rgbColorContrast(secondaryDark)};
      --ion-color-secondary-shade:  ${Color(secondaryDark).darken(shadeRatio)};
      --ion-color-secondary-tint: ${Color(secondaryDark).lighten(tintRatio)};
      --ion-color-tertiary:  ${tertiaryDark};
      --ion-color-tertiary-rgb: ${rgbColor(tertiaryDark)};
      --ion-color-tertiary-contrast: ${contrast(tertiaryDark)};
      --ion-color-tertiary-contrast-rgb: ${rgbColorContrast(tertiaryDark)};
      --ion-color-tertiary-shade: ${Color(tertiaryDark).darken(shadeRatio)};
      --ion-color-tertiary-tint:  ${Color(tertiaryDark).lighten(tintRatio)};
      --ion-color-success: ${successDark};
      --ion-color-success-rgb: ${rgbColor(successDark)};
      --ion-color-success-contrast: ${contrast(successDark)};
      --ion-color-success-contrast-rgb: ${rgbColorContrast(successDark)};
      --ion-color-success-shade: ${Color(successDark).darken(shadeRatio)};
      --ion-color-success-tint: ${Color(successDark).lighten(tintRatio)};
      --ion-color-warning: ${warningDark};
      --ion-color-warning-rgb: ${rgbColor(warningDark)};
      --ion-color-warning-contrast: ${contrast(warningDark)};
      --ion-color-warning-contrast-rgb: ${rgbColorContrast(warningDark)};
      --ion-color-warning-shade: ${Color(warningDark).darken(shadeRatio)};
      --ion-color-warning-tint: ${Color(warningDark).lighten(tintRatio)};
      --ion-color-danger: ${dangerDark};
      --ion-color-danger-rgb: ${rgbColor(dangerDark)};
      --ion-color-danger-contrast: ${contrast(dangerDark)};
      --ion-color-danger-contrast-rgb: ${rgbColorContrast(dangerDark)};
      --ion-color-danger-shade: ${Color(dangerDark).darken(shadeRatio)};
      --ion-color-danger-tint: ${Color(dangerDark).lighten(tintRatio)};
      --ion-color-dark: ${darkDark};
      --ion-color-dark-rgb: ${rgbColor(darkDark)};
      --ion-color-dark-contrast: ${contrast(darkDark)};
      --ion-color-dark-contrast-rgb: ${rgbColorContrast(darkDark)};
      --ion-color-dark-shade: ${Color(darkDark).darken(shadeRatio)};
      --ion-color-dark-tint: ${Color(darkDark).lighten(tintRatio)};
      --ion-color-medium: ${mediumDark};
      --ion-color-medium-rgb: ${rgbColor(mediumDark)};
      --ion-color-medium-contrast: ${contrast(mediumDark)};
      --ion-color-medium-contrast-rgb: ${rgbColorContrast(mediumDark)};
      --ion-color-medium-shade: ${Color(mediumDark).darken(shadeRatio)};
      --ion-color-medium-tint: ${Color(mediumDark).lighten(tintRatio)};
      --ion-color-light: ${lightDark};
      --ion-color-light-rgb: ${rgbColor(lightDark)};
      --ion-color-light-contrast: $${contrast(lightDark)};
      --ion-color-light-contrast-rgb: ${rgbColorContrast(lightDark)};
      --ion-color-light-shade: ${Color(lightDark).darken(shadeRatio)};
      --ion-color-light-tint: ${Color(lightDark).lighten(tintRatio)};
      --ion-toolbar-color: ${toolbarTextDark};
      --link-color: ${linkDark};
      --menu-background-color: ${menuBackgroundDark};
      --menu-background-color-shade: ${Color(menuBackgroundDark).darken(shadeRatio)};
      --menu-background-color-tint: ${Color(menuBackgroundDark).lighten(tintRatio)};
      --menu-text-color: ${menuTextDark};
      `;
  } else {
    return `
      --ion-item-background-color: ${contrast(light, 0.3)};
      --ion-item-text-color: ${contrast(dark, 0.3)};
      --ion-color-primary: ${primary};
      --ion-color-primary-rgb: ${rgbColor(primary)};
      --ion-color-primary-contrast: ${contrast(primary)};
      --ion-color-primary-contrast-rgb: ${rgbColorContrast(primary)};
      --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};
      --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio)};
      --ion-color-secondary: ${secondary};
      --ion-color-secondary-rgb: ${rgbColor(secondary)};
      --ion-color-secondary-contrast: ${contrast(secondary)};
      --ion-color-secondary-contrast-rgb: ${rgbColorContrast(secondary)};
      --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio)};
      --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
      --ion-color-tertiary:  ${tertiary};
      --ion-color-tertiary-rgb: ${rgbColor(tertiary)};
      --ion-color-tertiary-contrast: ${contrast(tertiary)};
      --ion-color-tertiary-contrast-rgb: ${rgbColorContrast(tertiary)};
      --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
      --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
      --ion-color-success: ${success};
      --ion-color-success-rgb: ${rgbColor(success)};
      --ion-color-success-contrast: ${contrast(success)};
      --ion-color-success-contrast-rgb: ${rgbColorContrast(success)};
      --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
      --ion-color-success-tint: ${Color(success).lighten(tintRatio)};
      --ion-color-warning: ${warning};
      --ion-color-warning-rgb: ${rgbColor(warning)};
      --ion-color-warning-contrast: ${contrast(warning)};
      --ion-color-warning-contrast-rgb: ${rgbColorContrast(warning)};
      --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
      --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
      --ion-color-danger: ${danger};
      --ion-color-danger-rgb: ${rgbColor(danger)};
      --ion-color-danger-contrast: ${contrast(danger)};
      --ion-color-danger-contrast-rgb: ${rgbColorContrast(danger)};
      --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
      --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
      --ion-color-dark: ${dark};
      --ion-color-dark-rgb: ${rgbColor(dark)};
      --ion-color-dark-contrast: ${contrast(dark)};
      --ion-color-dark-contrast-rgb: ${rgbColorContrast(dark)};
      --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
      --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
      --ion-color-medium: ${medium};
      --ion-color-medium-rgb: ${rgbColor(medium)};
      --ion-color-medium-contrast: ${contrast(medium)};
      --ion-color-medium-contrast-rgb: ${rgbColorContrast(medium)};
      --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
      --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
      --ion-color-light: ${light};
      --ion-color-light-rgb: ${rgbColor(light)};
      --ion-color-light-contrast: $${contrast(light)};
      --ion-color-light-contrast-rgb: ${rgbColorContrast(light)};
      --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
      --ion-color-light-tint: ${Color(light).lighten(tintRatio)};
      --ion-toolbar-color: ${toolbarText};
      --link-color: ${link};
      --menu-background-color: ${menuBackground};
      --menu-background-color-shade: ${Color(menuBackground).darken(shadeRatio)};
      --menu-background-color-tint: ${Color(menuBackground).lighten(tintRatio)};
      --menu-text-color: ${menuText};
      `;
  }
}

function contrast(color, ratio = 0.9) {
  color = Color(color);
  const contrastColor =  color.isDark() ? Color('#ffffff') : Color('#000000');
  return contrastColor.hex();
}

function rgbColor(color) {
  const rgbArray: Array<number> =  Color(color).rgb().array()
  return `${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}`;
}

function rgbColorContrast(color, ratio = 0.9) {
  color = Color(color);
  const contrastColor = color.isDark() ? Color('#ffffff') : Color('#000000');
  const rgbArray: Array<number> =  contrastColor.rgb().array()
  return `${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}`;
}
