import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { Globals } from './globals';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { PowerManagement } from '@ionic-native/power-management/ngx';
import { VERSION } from 'src/environments/version';
import { Version } from './models/version';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  version: Version;

  authStateSubscription: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public globals: Globals,
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private updates: SwUpdate,
    private powerManagement: PowerManagement
  ) {
    //https://medium.com/@amcdnl/version-stamping-your-app-with-the-angular-cli-d563284bb94d
    this.version = VERSION;
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //keep screen on with Wake Lock
      this.powerManagement.acquire().catch(err => console.error(err));
    });
  }

  async ngOnInit() {
    //set dark mode
    if (this.globals.darkMode) {
      document.body.classList.toggle('dark', true);
    }
    
    /**
     * Authenticate
     */
    this.authStateSubscription = this.afAuth.authState.subscribe(async (firebaseUser: firebase.User) => {
      if (!firebaseUser) {
        //Login Anonymously
        await this.auth.attemptAnonymousLogin();
      } else {
        //set firebase user
        this.globals.setFirebaseUser(firebaseUser);
        //set brand
        this.globals.setBrand('y9IPV8FsOst9yFxmVMZP');
      }
    });

    /**
     * Service Worker Reload App on New Version
     */
    this.updates.available.subscribe(async event => {
      //reload immediately
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
