import { Injectable } from '@angular/core';
import { StoreLocation } from 'src/app/models/store-location';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class StoreLocationService {

  constructor(
    public fireStore: AngularFirestore,
  ) { }

  getStoreLocation(locationUid: string): AngularFirestoreDocument<StoreLocation> { 
    return this.fireStore.doc<StoreLocation> (`/locations/${locationUid}`);
  }
}
