import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-full-screen',
  templateUrl: './spinner-full-screen.component.html',
  styleUrls: ['./spinner-full-screen.component.scss'],
})
export class SpinnerFullScreenComponent implements OnInit {

  @Input() reason: string;

  constructor() { }

  ngOnInit() {}

}
