import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TvDisplay } from 'src/app/models/tv-display';

@Injectable({
  providedIn: 'root'
})
export class TvDisplayService {

  constructor(
    public fireStore: AngularFirestore
    ) { }

  getTvDisplay(tvDisplayUid: string): AngularFirestoreDocument<TvDisplay> {
    return this.fireStore.doc<TvDisplay> (`/tvDisplays/${tvDisplayUid}`);
  }

  async updateTvDisplay(tvDisplay: TvDisplay): Promise<void> {
    try {
      let tvDisplayRef: AngularFirestoreDocument<TvDisplay> = this.fireStore.doc(`tvDisplays/${ tvDisplay.uid }`);
      await tvDisplayRef.update(tvDisplay);

    } catch(err) {
      throw err;
    }
  }
}
