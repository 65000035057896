import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CustomerRequest } from 'src/app/models/customer-request';
import { CustomerRequestMessage } from 'src/app/models/customer-request-message';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerRequestDataService {

  readonly api = `${environment.functionsURL}/v1`;

  constructor(
    public fireStore: AngularFirestore,
    private http: HttpClient
  ) {
    //
  }

  getCustomerRequests(locationUid: string, requestType?: 'curbside' | 'in-store-service'): AngularFirestoreCollection<CustomerRequest> {
    if (requestType) {
      return this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false)
      .where('requestType', '==', requestType)
      .where('resolved', '==', false)
      .where('spam', '==', false)
      .orderBy('createdDate', 'asc'));
    } else {
      return this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false)
      .where('resolved', '==', false)
      .where('spam', '==', false)
      .orderBy('createdDate', 'asc'));
    }
  }

  getPaginatedResolvedCustomerRequests(
    locationUid: string, 
    limit: number, 
    lastDocumentSnapshot?: firebase.firestore.QueryDocumentSnapshot): Promise<{customerRequests: CustomerRequest[], lastDocumentSnapshot: firebase.firestore.QueryDocumentSnapshot}> {
    return new Promise(async (resolve, reject) => {
      try {
        const currentTime = new firebase.firestore.Timestamp(moment().unix(), 0);

        let querySnapshot: firebase.firestore.QuerySnapshot

        if (!lastDocumentSnapshot) {
          querySnapshot = await this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('resolved', '==', true)
          .where('spam', '==', false)
          .orderBy('createdDate', 'desc')
          .limit(limit))
          .get().toPromise();
        } else {
          querySnapshot = await this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('resolved', '==', true)
          .where('spam', '==', false)
          .orderBy('createdDate', 'desc')
          .startAfter(lastDocumentSnapshot)
          .limit(limit))
          .get().toPromise();
        }

        //create array of transactions
        const customerRequests: CustomerRequest[] = [];
        for (let documentSnapshot of querySnapshot.docs) {
          customerRequests.push(documentSnapshot.data());
        }

        resolve({
          customerRequests: customerRequests, 
          lastDocumentSnapshot: querySnapshot.docs[querySnapshot.docs.length - 1]
        });

      } catch (err) {
        reject(err);
      }
    });
  }

  getPaginatedSpamCustomerRequests(
    locationUid: string, 
    limit: number, 
    lastDocumentSnapshot?: firebase.firestore.QueryDocumentSnapshot): Promise<{customerRequests: CustomerRequest[], lastDocumentSnapshot: firebase.firestore.QueryDocumentSnapshot}> {
    return new Promise(async (resolve, reject) => {
      try {
        const currentTime = new firebase.firestore.Timestamp(moment().unix(), 0);

        let querySnapshot: firebase.firestore.QuerySnapshot

        if (!lastDocumentSnapshot) {
          querySnapshot = await this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('spam', '==', true)
          .orderBy('createdDate', 'desc')
          .limit(limit))
          .get().toPromise();
        } else {
          querySnapshot = await this.fireStore.collection<CustomerRequest>(`/customerRequests`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('spam', '==', true)
          .orderBy('createdDate', 'desc')
          .startAfter(lastDocumentSnapshot)
          .limit(limit))
          .get().toPromise();
        }

        //create array of transactions
        const customerRequests: CustomerRequest[] = [];
        for (let documentSnapshot of querySnapshot.docs) {
          customerRequests.push(documentSnapshot.data());
        }

        resolve({
          customerRequests: customerRequests, 
          lastDocumentSnapshot: querySnapshot.docs[querySnapshot.docs.length - 1]
        });

      } catch (err) {
        reject(err);
      }
    });
  }

  getCustomerRequest(customerRequestUid: string): AngularFirestoreDocument<CustomerRequest> {
    return this.fireStore.doc<CustomerRequest>(`/customerRequests/${customerRequestUid}`);
  }

  setAsResolved(customerRequest: CustomerRequest): Promise<CustomerRequest> {
    return new Promise((resolve, reject) => {
    
      const url = `${this.api}/customer-requests/${customerRequest.uid}/resolved`;
      
      this.http.put<CustomerRequest>(url, {}).subscribe(
        res => resolve(res),
        error => reject(error)
      );
    });
  }

  setAsUnresolved(customerRequest: CustomerRequest): Promise<CustomerRequest> {
    return new Promise((resolve, reject) => {
    
      const url = `${this.api}/customer-requests/${customerRequest.uid}/unresolved`;
      
      this.http.put<CustomerRequest>(url, {}).subscribe(
        res => resolve(res),
        error => reject(error)
      );
    });
  }

  setAsSpam(customerRequest: CustomerRequest): Promise<CustomerRequest> {
    return new Promise((resolve, reject) => {
    
      const url = `${this.api}/customer-requests/${customerRequest.uid}/spam`;
      
      this.http.put<CustomerRequest>(url, {}).subscribe(
        res => resolve(res),
        error => reject(error)
      );
    });
  }

  setAsNotSpam(customerRequest: CustomerRequest): Promise<CustomerRequest> {
    return new Promise((resolve, reject) => {
    
      const url = `${this.api}/customer-requests/${customerRequest.uid}/not-spam`;
      
      this.http.put<CustomerRequest>(url, {}).subscribe(
        res => resolve(res),
        error => reject(error)
      );
    });
  }
}
