import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Advertisement } from 'src/app/models/advertisement';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementDataService {

  constructor(
    public fireStore: AngularFirestore,
  ) {
  }

  getAdvertisements(locationUid: string): AngularFirestoreCollection<Advertisement> {
    return this.fireStore.collection<Advertisement>(`/advertisements`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false));
  }

  getAdvertisementsByUids(advertisementUids: string[]): AngularFirestoreCollection<Advertisement> {
    return this.fireStore.collection<Advertisement>(`/advertisements`, ref => ref
      .where('uid', 'in', advertisementUids)
      .where('deleted', '==', false));
  }

  getAdvertisement(advertisementUid: string): AngularFirestoreDocument<Advertisement> {
    return this.fireStore.doc<Advertisement>(`/advertisements/${advertisementUid}`);
  }

}
