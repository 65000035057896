import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TvDisplayAsset } from 'src/app/models/tv-display-asset';

@Injectable({
  providedIn: 'root'
})
export class TvDisplayAssetDataService {

  constructor(
    public fireStore: AngularFirestore,
    ) { }

  getTvDisplayAsset(tvDisplayAssetUid: string): AngularFirestoreDocument<TvDisplayAsset> { 
    return this.fireStore.doc<TvDisplayAsset> (`/tvDisplayAssets/${tvDisplayAssetUid}`);
  }

  getTvDisplayAssetsByUids(tvDisplayAssetUids: string[]): AngularFirestoreCollection<TvDisplayAsset> {
    return this.fireStore.collection<TvDisplayAsset>(`/tvDisplayAssets`, ref => ref
    .where('uid', 'in', tvDisplayAssetUids)
    .where('deleted', '==', false));
  }

}
