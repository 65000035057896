import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { TriviaGameQuestion } from 'src/app/models/trivia-game-question';
import * as moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TriviaGameQuestionsDataService {

  constructor(
    public fireStore: AngularFirestore,
  ) { }

  getTriviaGameQuestions(triviaGameUid: string, locationUid?: string): AngularFirestoreCollection<TriviaGameQuestion> { 
    if (locationUid) {
      return this.fireStore.collection<TriviaGameQuestion> (`/triviaGameQuestions`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('triviaGameUid', '==', triviaGameUid)
      .where('deleted', '==', false)
      .orderBy('order'));
    } else {
      return this.fireStore.collection<TriviaGameQuestion> (`/triviaGameQuestions`, ref => ref
      .where('public', '==', true)
      .where('triviaGameUid', '==', triviaGameUid)
      .where('deleted', '==', false)
      .orderBy('order'));
    }
  }

  getPublicTriviaGameQuestions(triviaGameUid: string, locationUid?: string): AngularFirestoreCollection<TriviaGameQuestion> { 
    if (locationUid) {
      return this.fireStore.collection<TriviaGameQuestion> (`/triviaGameQuestions`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('triviaGameUid', '==', triviaGameUid)
      .where('public', '==', true)
      .where('deleted', '==', false)
      .orderBy('order'));
    } else {
      return this.fireStore.collection<TriviaGameQuestion> (`/triviaGameQuestions`, ref => ref
      .where('triviaGameUid', '==', triviaGameUid)
      .where('public', '==', true)
      .where('deleted', '==', false)
      .orderBy('order'));
    }
    
  }

  getLivePublicTriviaGameQuestions(triviaGameUid: string): AngularFirestoreCollection<TriviaGameQuestion> { 
    return this.fireStore.collection<TriviaGameQuestion> (`/triviaGameQuestions`, ref => ref
    .where('triviaGameUid', '==', triviaGameUid)
    .where('public', '==', true)
    .where('started', '==', true)
    .where('ended', '==', false)
    .where('deleted', '==', false));
  }

  getTriviaGameQuestion(triviaGameQuestionUid: string): AngularFirestoreDocument<TriviaGameQuestion> { 
    return this.fireStore.doc<TriviaGameQuestion> (`/triviaGameQuestions/${triviaGameQuestionUid}`);
  }

}
