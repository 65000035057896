import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Version } from 'src/app/models/version';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
})
export class ErrorAlertComponent implements OnInit {

  version: Version;

  constructor(
    private toastCtrl: ToastController
  ) { 
    this.version = VERSION
  }

  ngOnInit() {}

  async show(err: string | any) {
    console.error(err);
    
    let errorMessage: string;

    if (typeof err === 'string' || err instanceof String) {
      errorMessage = err.toString();
    } else if (err.error && err.error.message) {
      errorMessage = err.error.message;
    } else if (err.message) {
      errorMessage = err.message;
    } else {
      errorMessage = err.error;
    }
    
    const toast = await this.toastCtrl.create({
      message: `[v${this.version.version} (${this.version.hash})] ${errorMessage}`,
      duration: 10000,
      color: 'danger'
    });
    toast.present();
  }
}
