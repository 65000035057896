import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHhmmss'
})
export class SecondsToHhmmssPipe implements PipeTransform {

  transform(secs: any, ...args: any[]): any {
    let minutes = Math.floor(secs / 60);
    secs = secs%60;
    let hours = Math.floor(minutes/60)
    minutes = minutes%60;

    if (hours) {
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    } else {
      return `${this.pad(minutes)}:${this.pad(secs)}`;
    }
  }

  private pad(num) {
    return ("0"+num).slice(-2);
  }

}
