import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { TriviaGame } from 'src/app/models/trivia-game';

@Injectable({
  providedIn: 'root'
})
export class TriviaGamesDataService {

  constructor(
    public fireStore: AngularFirestore,
  ) { }

  getAutomatedPublicTriviaGames(futureOnly?: boolean, upToHoursOld?: number): AngularFirestoreCollection<TriviaGame> { 
    if (futureOnly) {
      const hours: number = upToHoursOld || 0;
      const currentTime = new firebase.firestore.Timestamp(moment().subtract(hours, 'hours').unix(), 0);
      return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
      .where('automated', '==', true)
      .where('deleted', '==', false)
      .where('public', '==', true)
      .where('end', '>', currentTime)
      .orderBy('end'));
    } else {
      return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
      .where('automated', '==', true)
      .where('deleted', '==', false)
      .where('public', '==', true)
      .orderBy('end', 'desc'));
    }
  }

  getLiveAutomatedTriviaGames() {
    return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
    .where('automated', '==', true)
    .where('public', '==', true)
    .where('deleted', '==', false)
    .where('gameStarted', '==', true)
    .where('gameEnded', '==', false));
  }

  getPaginatedPastAutomatedTriviaGames(
    limit: number, 
    lastDocumentSnapshot?: firebase.firestore.QueryDocumentSnapshot): Promise<{triviaGames: TriviaGame[], lastDocumentSnapshot: firebase.firestore.QueryDocumentSnapshot}> {
    return new Promise(async (resolve, reject) => {
      try {
        const currentTime = new firebase.firestore.Timestamp(moment().unix(), 0);

        let querySnapshot: firebase.firestore.QuerySnapshot

        if (!lastDocumentSnapshot) {
          querySnapshot = await this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
          .where('automated', '==', true)
          .where('public', '==', true)
          .where('deleted', '==', false)
          .where('end', '<', currentTime)
          .orderBy('end', 'desc')
          .limit(limit))
          .get().toPromise();
        } else {
          querySnapshot = await this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
          .where('automated', '==', true)
          .where('public', '==', true)
          .where('deleted', '==', false)
          .where('end', '<', currentTime)
          .orderBy('end', 'desc')
          .startAfter(lastDocumentSnapshot)
          .limit(limit))
          .get().toPromise();
        }

        //create array of transactions
        const triviaGames: TriviaGame[] = [];
        for (let documentSnapshot of querySnapshot.docs) {
          triviaGames.push(documentSnapshot.data());
        }

        resolve({
          triviaGames: triviaGames, 
          lastDocumentSnapshot: querySnapshot.docs[querySnapshot.docs.length - 1]
        });

      } catch (err) {
        reject(err);
      }
    });
  }

  getAllTriviaGames(locationUid: string, futureOnly?: boolean): AngularFirestoreCollection<TriviaGame> { 
    if (futureOnly) {
      const currentTime = new firebase.firestore.Timestamp(moment().unix(), 0);
      return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false)
      .where('end', '>', currentTime)
      .orderBy('end'));
    } else {
      return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false)
      .orderBy('end', 'desc'));
    }
  }

  getLatestTriviaGames(locationUid: string) {
    return this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
      .where('locationUid', '==', locationUid)
      .where('deleted', '==', false)
      .orderBy('end', 'desc')
      .limit(50));
  }

  getPaginatedPastTriviaGames(
    locationUid: string, 
    limit: number, 
    lastDocumentSnapshot?: firebase.firestore.QueryDocumentSnapshot): Promise<{triviaGames: TriviaGame[], lastDocumentSnapshot: firebase.firestore.QueryDocumentSnapshot}> {
    return new Promise(async (resolve, reject) => {
      try {
        const currentTime = new firebase.firestore.Timestamp(moment().unix(), 0);

        let querySnapshot: firebase.firestore.QuerySnapshot

        if (!lastDocumentSnapshot) {
          querySnapshot = await this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('end', '<', currentTime)
          .orderBy('end', 'desc')
          .limit(limit))
          .get().toPromise();
        } else {
          querySnapshot = await this.fireStore.collection<TriviaGame> (`/triviaGames`, ref => ref
          .where('locationUid', '==', locationUid)
          .where('deleted', '==', false)
          .where('end', '<', currentTime)
          .orderBy('end', 'desc')
          .startAfter(lastDocumentSnapshot)
          .limit(limit))
          .get().toPromise();
        }

        //create array of transactions
        const triviaGames: TriviaGame[] = [];
        for (let documentSnapshot of querySnapshot.docs) {
          triviaGames.push(documentSnapshot.data());
        }

        resolve({
          triviaGames: triviaGames, 
          lastDocumentSnapshot: querySnapshot.docs[querySnapshot.docs.length - 1]
        });

      } catch (err) {
        reject(err);
      }
    });
  }

  getTriviaGame(triviaGameUid: string): AngularFirestoreDocument<TriviaGame> { 
    return this.fireStore.doc<TriviaGame> (`/triviaGames/${triviaGameUid}`);
  }

}
