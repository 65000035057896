import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Globals } from 'src/app/globals';
import { TriviaGame } from 'src/app/models/trivia-game';
import { TriviaGameQuestion } from 'src/app/models/trivia-game-question';
import { TriviaGameQuestionsDataService } from 'src/app/services/trivia-game-questions-data/trivia-game-questions-data.service';
import { fadeAnimation } from 'src/app/animations';
import { combineLatest, of, Subscription } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Advertisement } from 'src/app/models/advertisement';
import { AdvertisementDataService } from 'src/app/services/advertisement-data/advertisement-data.service';
import { TriviaGamesDataService } from 'src/app/services/trivia-games-data/trivia-games-data.service';
import { Brand } from 'src/app/models/brand';
import { TvDisplay } from 'src/app/models/tv-display';

@Component({
  selector: 'app-automated-trivia',
  templateUrl: './automated-trivia.component.html',
  styleUrls: ['./automated-trivia.component.scss'],
  animations: [fadeAnimation]
})
export class AutomatedTriviaComponent implements OnInit, OnDestroy {

  @Input() tvDisplay: TvDisplay;

  subscription: Subscription;
  brand: Brand;
  loadingComplete: boolean;
  triviaGame: TriviaGame;
  liveTriviaGameQuestions: TriviaGameQuestion[] = [];
  liveTriviaGameTimers: number[] = [];
  advertisements: Advertisement[];
  
  constructor(
    private globals: Globals,
    private triviaGameData: TriviaGamesDataService,
    private triviaGameQuestionsData: TriviaGameQuestionsDataService,
    private advertisementsData: AdvertisementDataService,
  ) { }

  ngOnInit() {

    this.subscription = combineLatest([
      this.globals.brand$,
      this.triviaGameData.getLiveAutomatedTriviaGames().valueChanges().pipe(
        switchMap(triviaGames => {

          //find local game if available
          const localAutomatedTriviaGame = triviaGames.find(game => game.locationUid === this.tvDisplay.locationUid);
          //find global game if available
          const globalAutomatedTriviaGame = triviaGames.find(game => game.global === true);

          const triviaGame: TriviaGame = localAutomatedTriviaGame || globalAutomatedTriviaGame;

          const emptyTriviaGameQuestions: TriviaGameQuestion[] = [];
          const emptyAdvertisements: Advertisement[] = [];

          const getLivePublicTriviaGameQuestions$ = (triviaGame && triviaGame.uid)? this.triviaGameQuestionsData.getLivePublicTriviaGameQuestions(triviaGame.uid).valueChanges() : of(emptyTriviaGameQuestions);
          const getAdvertisementsByUids$ = (triviaGame && triviaGame.advertisementUids && triviaGame.advertisementUids.length > 0)? this.advertisementsData.getAdvertisementsByUids(triviaGame.advertisementUids).valueChanges() : of(emptyAdvertisements);


          return combineLatest([
            getLivePublicTriviaGameQuestions$,
            getAdvertisementsByUids$
          ]).pipe(
            map(([triviaGameQuestions, advertisements]) => ({triviaGame, triviaGameQuestions, advertisements})
          ));

        })
      )
    ]).subscribe(([brand, {triviaGame, triviaGameQuestions, advertisements}]) => {
      this.brand = brand;
      this.triviaGame = triviaGame;
      this.liveTriviaGameQuestions = triviaGameQuestions;
      this.advertisements = advertisements;
      this.loadingComplete = true;
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
