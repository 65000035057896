import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest, Subscription, timer} from 'rxjs';
import { TvDisplay } from 'src/app/models/tv-display';
import { TvDisplayAsset } from 'src/app/models/tv-display-asset';
import { TvDisplayAssetDataService } from 'src/app/services/tv-display-asset-data/tv-display-asset-data.service';
import moment from 'moment-timezone';
import { ErrorAlertComponent } from '../error-alert/error-alert.component';
import { Capacitor, Plugins, FilesystemDirectory } from '@capacitor/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
const { Filesystem, Storage } = Plugins;
import { writeFile } from 'capacitor-blob-writer'
import { Globals } from 'src/app/globals';
import { fadeAnimation } from 'src/app/animations';

@Component({
  selector: 'app-images-and-video',
  templateUrl: './images-and-video.component.html',
  styleUrls: ['./images-and-video.component.scss'],
  animations: [fadeAnimation]
})
export class ImagesAndVideoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() tvDisplay: TvDisplay;

  subscriptions: Subscription[];
  tvDisplayAssets: any; //key-value structure where uid is key, TvDisplayAsset is value

  timerSubscription: Subscription;
  nextAssetIndex: number;
  seconds: number;
  currentTvDisplayAsset: TvDisplayAsset;

  noAvailableAssets: boolean;
  
  downloadProgress:number;

  filesDirectory: string = 'sp-files';

  constructor(
    private tvDisplayAssetData: TvDisplayAssetDataService,
    private errorAlert: ErrorAlertComponent,
    private http: HttpClient,
    private globals: Globals
  ) { }

  ngOnInit() {
    if (this.tvDisplay) this.runAssets(this.tvDisplay);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentTvDisplay: TvDisplay = changes.tvDisplay.currentValue;
    const previousTvDisplay: TvDisplay = changes.tvDisplay.previousValue;

    if (currentTvDisplay && previousTvDisplay && currentTvDisplay.tvDisplayAssetUids.toString() !== previousTvDisplay.tvDisplayAssetUids.toString()) {
      this.runAssets(changes.tvDisplay.currentValue);
    }
  }

  async runAssets(tvDisplay: TvDisplay) {
    try {

      //unsubscribe to reset
      if (this.timerSubscription) this.timerSubscription.unsubscribe();
      if (this.subscriptions && this.subscriptions.length) {
        for (const [index] of this.subscriptions.entries()) {
          if (this.subscriptions[index]) this.subscriptions[index].unsubscribe();
        }
      }

      //init subscriptions array
      this.subscriptions = [];

      this.nextAssetIndex = 0;
      this.seconds = 0;

      //clear old tvDisplayAssets
      this.tvDisplayAssets = {};
      //delete all assets
      await this.rmdir(this.filesDirectory);

      if (tvDisplay.tvDisplayAssetUids && tvDisplay.tvDisplayAssetUids.length > 0) {
        //create subscriptions for each asset
        for (const tvDisplayAssetUid of tvDisplay.tvDisplayAssetUids) {
          this.subscriptions.push(this.tvDisplayAssetData.getTvDisplayAsset(tvDisplayAssetUid).valueChanges().subscribe(async (tvDisplayAsset) => {
            //save file locally if native
            this.downloadTvDisplayAsset(tvDisplayAsset);
          }));
        }

        this.timerSubscription = timer(1000, 1000).subscribe(seconds => {

          if (this.tvDisplayAssets && tvDisplay.tvDisplayAssetUids.length > 0) {
    
            if (!this.currentTvDisplayAsset || (this.seconds > this.currentTvDisplayAsset.displayTimeInSeconds)) {
              const potentialTvDisplayAsset: TvDisplayAsset = this.tvDisplayAssets[tvDisplay.tvDisplayAssetUids[this.nextAssetIndex]];

              // display if valid and scheduled asset
              if (potentialTvDisplayAsset && potentialTvDisplayAsset.dailyStartTime && potentialTvDisplayAsset.dailyEndTime && potentialTvDisplayAsset.startDate && potentialTvDisplayAsset.endDate) {
                
                //get time variables
                const currentDayString: string = moment().format('dddd').toLowerCase();
                const startHoursAndMinutes: string[] = potentialTvDisplayAsset.dailyStartTime.split(':');
                const startTime: moment.Moment = moment().hours(parseInt(startHoursAndMinutes[0])).minutes(parseInt(startHoursAndMinutes[1])).seconds(0);
                const endHoursAndMinutes: string[] = potentialTvDisplayAsset.dailyEndTime.split(':');
                const endTime: moment.Moment = moment().hours(parseInt(endHoursAndMinutes[0])).minutes(parseInt(endHoursAndMinutes[1])).seconds(0);
                const startDate: moment.Moment = moment(potentialTvDisplayAsset.startDate.toDate());
                const endDate: moment.Moment = moment(potentialTvDisplayAsset.endDate.toDate());

                // Check if asset should be displayed
                if (
                  potentialTvDisplayAsset[currentDayString] === true &&
                  startDate.isSameOrBefore(moment()) && 
                  endDate.isSameOrAfter(moment()) &&
                  startTime.isSameOrBefore(moment()) && 
                  endTime.isSameOrAfter(moment()) &&
                  !potentialTvDisplayAsset.deleted
                ) {
                  //set asset and reset seconds for this asset being displayed
                  this.seconds = 0;
                  this.noAvailableAssets = false;
                  this.currentTvDisplayAsset = potentialTvDisplayAsset;
                }
              }
      
              //increment asset index
              this.nextAssetIndex += 1;
      
              if (this.nextAssetIndex >= tvDisplay.tvDisplayAssetUids.length) {
                //reset asset index
                this.nextAssetIndex = 0;
              }
            }

          } else {
            this.noAvailableAssets = true;
            this.currentTvDisplayAsset = undefined;
          }
    
          // increment seconds
          this.seconds += 1;
        });

      } else {
        this.noAvailableAssets = true;
      }

    } catch(err) {
      this.errorAlert.show(err);
    }    
  }

  async ngOnDestroy() {
    await this.rmdir(this.filesDirectory);
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
    if (this.subscriptions && this.subscriptions.length) {
      for (const [index] of this.subscriptions.entries()) {
        if (this.subscriptions[index]) this.subscriptions[index].unsubscribe();
      }
    }
  }

  //REFERENCES:
  //https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
  //https://www.joshmorony.com/using-the-capacitor-filesystem-api-to-store-photos/
  //https://capacitorjs.com/docs/apis/filesystem
  //https://www.youtube.com/watch?v=KdIgThOqqhU&list=PLNFwX8PVq5q5SFTJwn-Ei_9hOxt21Erc-
  //https://github.com/diachedelic/capacitor-blob-writer
  private downloadTvDisplayAsset(tvDisplayAsset: TvDisplayAsset) {
    if (this.globals.isNative) {
      this.http.get(tvDisplayAsset.tvDisplayAssetUrl, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe(async event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.downloadProgress = Math.round((100*event.loaded) / event.total);
        } else if (event.type === HttpEventType.Response) {
          //set download progress
          this.downloadProgress = 0;
          //use file name
          // const name = tvDisplayAsset.tvDisplayAssetUrl.substr(tvDisplayAsset.tvDisplayAssetUrl.lastIndexOf('/') + 1);
          const name = tvDisplayAsset.uid;
          const blob = event.body;

          const { uri } = await writeFile({
            path: `${this.filesDirectory}/${name}`,
            directory: FilesystemDirectory.Data,
        
            // data must be a Blob (creating a Blob which wraps other data types is trivial)
            data: blob,
        
            // create intermediate directories if they don't already exist
            // default: false
            recursive: true
          })
        
          const src = Capacitor.convertFileSrc(uri)

          tvDisplayAsset.tvDisplayAssetUrl = src;
          this.tvDisplayAssets[tvDisplayAsset.uid] = tvDisplayAsset;
        }
      });
    } else {
      this.tvDisplayAssets[tvDisplayAsset.uid] = tvDisplayAsset;
    }
  }

  async rmdir(filesDirectory: string) {
    try {
      if (this.globals.isNative) {

        await Filesystem.rmdir({
          path: filesDirectory,
          directory: FilesystemDirectory.Data,
          recursive: true
        });
      }
      return;
    } catch(err) {
      // this.errorAlert.show(`Unable to remove directory.  ${err}`);
    }
  }
  

}
