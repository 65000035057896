import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyOrFree'
})
export class CurrencyOrFreePipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number): string {
    if (value > 0) {
      return this.currencyPipe.transform(value);
    } else {
      return 'FREE'
    }    
  }

}
