import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth
  ) { }

  async attemptAnonymousLogin(): Promise<firebase.User> {
    try {
      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInAnonymously();
      return userCredential.user;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

}
