import { Injectable } from '@angular/core';
import { Brand } from 'src/app/models/brand';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BrandDataService {

  constructor(
    public fireStore: AngularFirestore,
  ) { }

  getBrand(brandUid: string): AngularFirestoreDocument<Brand> { 
    //return brand
    return this.fireStore.doc<Brand> (`/brands/${brandUid}`);
  }
  
  getBrands(): AngularFirestoreCollection<Brand> {
    return this.fireStore.collection<Brand>(`/brands`, ref => ref
      .orderBy('name', 'asc')
    );
  }

}
