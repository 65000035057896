import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFrom24To12Format'
})
export class ConvertFrom24To12FormatPipe implements PipeTransform {

  transform(time: any): any {
    let hour:string = (time.split(':'))[0]
    let min:string = (time.split(':'))[1]

    // daypart definition
    let part = parseInt(hour) > 11 ? 'PM' : 'AM';

    // minute formatting
    min = (min+'').length == 1 ? `0${min}` : min;

    // hour formatting
    if (parseInt(hour) > 12) {
      hour = (parseInt(hour) - 12).toString();
    } else if (parseInt(hour) === 0) {
      hour = '12';
    } else {
      hour = (parseInt(hour)).toString();
    }

    return `${hour}:${min} ${part}`
  }

}
