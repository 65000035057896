import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToTitle'
})
export class CamelToTitlePipe implements PipeTransform {

  transform(value: string, args?: string): any {
    if (!value) {
      return value;
    }

    const camel2title = value
      .replace(/([A-Z])/g, (match) => ` ${match}`)    // inject space before the upper case letters
      .replace(/^./, (match) => match.toUpperCase());    // replace first char with upper case
      
    return camel2title;
  }

}