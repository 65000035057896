import { NgModule } from '@angular/core';

import { MomentFormatPipe } from './moment-format/moment-format.pipe'
import { ConvertFrom24To12FormatPipe } from './convert-from24-to12-format/convert-from24-to12-format.pipe';
import { StripePipe } from './stripe/stripe.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { PhonePipe } from './phone/phone.pipe';
import { CurrencyOrFreePipe } from './currency-or-free/currency-or-free.pipe';
import { CurrencyPipe } from '@angular/common';
import { OrdinalPipe } from './ordinal/ordinal.pipe';
import { CamelToTitlePipe } from './camel-to-title/camel-to-title.pipe';
import { SecondsToHhmmssPipe } from './seconds-to-hhmmss/seconds-to-hhmmss.pipe';

@NgModule({
  declarations: [
    MomentFormatPipe,
    ConvertFrom24To12FormatPipe,
    StripePipe,
    KeysPipe,
    PhonePipe,
    CurrencyOrFreePipe,
    OrdinalPipe,
    CamelToTitlePipe,
    SecondsToHhmmssPipe
  ],
  exports: [
    MomentFormatPipe,
    ConvertFrom24To12FormatPipe,
    StripePipe,
    KeysPipe,
    PhonePipe,
    CurrencyOrFreePipe,
    OrdinalPipe,
    CamelToTitlePipe,
    SecondsToHhmmssPipe
  ],
  providers: [
    CurrencyPipe
  ]
})
export class PipesModule {}
