import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from 'src/app/pipes/pipes.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AutomatedTriviaComponent } from './automated-trivia/automated-trivia.component';
import { ImagesAndVideoComponent } from './images-and-video/images-and-video.component';
import { SpinnerFullScreenComponent } from './spinner-full-screen/spinner-full-screen.component';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { CurbsideComponent } from './curbside/curbside.component';
import { TimeSinceTimerComponent } from './time-since-timer/time-since-timer.component';
import { CustomerRequestMessagesComponent } from './customer-request-messages/customer-request-messages.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    PipesModule,
    QRCodeModule
  ],
  declarations: [
    AutomatedTriviaComponent,
    ImagesAndVideoComponent,
    CurbsideComponent,
    SpinnerFullScreenComponent,
    ErrorAlertComponent,
    TimeSinceTimerComponent,
    CustomerRequestMessagesComponent
  ],
  entryComponents: [
    ErrorAlertComponent
  ],
  exports: [
    AutomatedTriviaComponent,
    ImagesAndVideoComponent,
    CurbsideComponent,
    SpinnerFullScreenComponent, 
    TimeSinceTimerComponent,
    CustomerRequestMessagesComponent
  ],
  providers: [SpinnerFullScreenComponent, ErrorAlertComponent, TimeSinceTimerComponent, CustomerRequestMessagesComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Required for third-party web components
  ]
  
})
export class ComponentsModule {}
