import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CustomerRequestMessage } from 'src/app/models/customer-request-message';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerRequest } from 'src/app/models/customer-request';

@Injectable({
  providedIn: 'root'
})
export class CustomerRequestMessageDataService {

  readonly api = `${environment.functionsURL}/v1`;

  constructor(
    public fireStore: AngularFirestore,
    private http: HttpClient
  ) {
    //
  }

  getCustomerRequestMessagesForCustomerRequest(customerRequest: CustomerRequest): AngularFirestoreCollection<CustomerRequestMessage> {
    return this.fireStore.collection<CustomerRequestMessage>(`/customerRequestMessages`, ref => ref
    .where('customerRequestUid', '==', customerRequest.uid)
    .where('locationUid', '==', customerRequest.locationUid)
    .where('deleted', '==', false)
    .orderBy('createdDate', 'desc'));
  }

  saveCustomerRequestMessage(customerRequestMessage: CustomerRequestMessage) {
    return new Promise((resolve, reject) => {
    
      const url = `${this.api}/customer-requests/customer-request-message`;
      
      this.http.post<CustomerRequestMessage>(url, { customerRequestMessage: customerRequestMessage }).subscribe(
        res => resolve(res),
        error => reject(error)
      );
    });
  }
}
