import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Globals } from 'src/app/globals';
import { CustomerRequest } from 'src/app/models/customer-request';
import { StoreLocation } from 'src/app/models/store-location';
import { CustomerRequestDataService } from 'src/app/services/customer-request-data/customer-request-data.service';
import { CustomerRequestMessageDataService } from 'src/app/services/customer-request-message-data/customer-request-message-data.service';
import { ErrorAlertComponent } from '../error-alert/error-alert.component';
import moment from 'moment-timezone';
import { TvDisplay } from 'src/app/models/tv-display';

@Component({
  selector: 'app-curbside',
  templateUrl: './curbside.component.html',
  styleUrls: ['./curbside.component.scss'],
})
export class CurbsideComponent implements OnInit, OnDestroy {

  @Input() tvDisplay: TvDisplay;
  @Input() storeLocation: StoreLocation;

  timerSubscription: Subscription;
  currentDate: moment.Moment;

  subscription: Subscription;
  customerRequests: CustomerRequest[];

  constructor(
    public customerRequestDataService: CustomerRequestDataService,
    private globals: Globals,
    private errorAlert: ErrorAlertComponent,
    private customerRequestMessageData: CustomerRequestMessageDataService
  ) { 
    this.currentDate = moment();
  }

  ngOnInit() {
    this.subscription = this.customerRequestDataService.getCustomerRequests(this.tvDisplay.locationUid, 'curbside').valueChanges()
    .subscribe((customerRequests) => {
      this.customerRequests = customerRequests;
    });

    this.timerSubscription = timer(1000, 1000).subscribe(seconds => {
      this.currentDate = moment();
    });
  }

  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
    if (this.subscription) this.subscription.unsubscribe();
  }

}
